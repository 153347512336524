@import "react-tippy/dist/tippy.css";
@import "./pagination.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Montserrat", sans-serif;
}

.reply-branch {
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    top: -50%;
    left: 10%;
    border: 1px solid #ccc;
    width: 200px;
    height: 100%;
    border-radius: 10px;
  }
}
