.custom-select {
  &--limit-page {
    height: 30px;
    color: var(--color-blue-2);
    border-radius: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 3rem;
    &:focus {
      outline: 0;
      box-shadow: 0 0 3px var(--color-primary);
    }
  }
}

.custom__pagination {
  &__item {
    border: 1px solid;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
    background-color: var(--color-blue-2);
    color: var(--color-primary);
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
    }
    &--active {
      background-color: var(--color-primary);
      color: black;
      border: 1px solid var(--color-primary);
      font-weight: bold;
    }
  }
}
